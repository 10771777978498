import React from "react";

const FarewellMessage = () => {
  const handleButtonClick = () => {
    window.location.href = "https://niteshagrawal.net";
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)",
        color: "#fff",
        fontFamily: "'Poppins', sans-serif",
      }}
    >
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          padding: "40px",
          borderRadius: "15px",
          boxShadow: "0 8px 15px rgba(0, 0, 0, 0.2)",
          textAlign: "center",
          maxWidth: "600px",
        }}
      >
        <h1
          style={{
            fontSize: "36px",
            marginBottom: "20px",
            fontWeight: "600",
            letterSpacing: "1px",
          }}
        >
          Farewell 🌟
        </h1>
        <p
          style={{
            fontSize: "18px",
            lineHeight: "1.8",
            marginBottom: "30px",
          }}
        >
          Today marks the end of an incredible chapter in my professional
          journey. As I bid farewell to Repute and my wonderful colleagues, I
          carry with me a treasure trove of experiences, learnings, and
          cherished memories. It's been an honor to collaborate with such
          talented individuals, and I’m deeply grateful for the opportunities to
          grow both personally and professionally. While I’m excited about the
          road ahead, I will always look back fondly at the bonds we’ve built
          and the milestones we’ve achieved together. Wishing you all continued
          success, and I hope our paths cross again someday!
        </p>
        <button
          style={{
            padding: "10px 25px",
            fontSize: "16px",
            fontWeight: "500",
            color: "#6a11cb",
            background: "#fff",
            border: "none",
            borderRadius: "25px",
            cursor: "pointer",
            transition: "transform 0.3s ease",
          }}
          onMouseOver={(e) => (e.target.style.transform = "scale(1.1)")}
          onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
          onClick={handleButtonClick}
        >
          Stay Connected
        </button>
      </div>
    </div>
  );
};

export default FarewellMessage;
